<template>
  <div
    class="image"
    :class="[
      {
        'image--loaded': !loading,
        'image-aspect--banner': bannerAspectRatio,
        'image--error': error,
        'image--fit-cover': fitCover,
        'image--fit-contain': objectFitContain
      },
      backgroundImageClass
    ]"
  >
    <picture>
      <source
        v-if="picture && picture.mobile"
        :media="`(max-width: ${breakpoint - 1}px) and (orientation: portrait)`"
        :srcset="picture.mobile.srcset"
        :width="picture.mobile.width"
        :height="picture.mobile.height"
        @error="onError"
      />
      <source
        v-if="picture && picture.desktop"
        :media="`(min-width: ${breakpoint}px)`"
        :srcset="picture.desktop.srcset"
        :width="picture.desktop.width"
        :height="picture.desktop.height"
        @error="onError"
      />

      <img
        v-if="src"
        ref="imageRef"
        :data-src="src"
        :srcset="srcset"
        :sizes="sizes"
        :width="width"
        :height="height"
        :alt="alt"
        loading="lazy"
        @load="onLoad"
        @error="onError"
      />
      <img
        v-else-if="responsiveImage"
        ref="imageRef"
        :data-src="responsiveImage.url"
        :srcset="responsiveImage.srcset"
        :sizes="responsiveImage.sizes"
        :width="width"
        :height="height"
        :alt="alt"
        loading="lazy"
        @load="onLoad"
        @error="onError"
      />
      <img
        v-else-if="picture && picture.default"
        ref="imageRef"
        :data-src="picture.default.src"
        :srcset="srcset"
        :sizes="sizes"
        :width="picture.default.width"
        :height="picture.default.height"
        :alt="alt"
        loading="lazy"
        @load="onLoad"
        @error="onError"
      />
    </picture>
    <p v-if="(responsiveImage && responsiveImage.imageIsPlaceHolder) || error">
      Images available soon
    </p>
  </div>
</template>

<script lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import {
  EFallbackImageTypes,
  IResponsiveImage,
  TMobileDesktopImage
} from '~/types/image'
export default {
  name: 'OsImage',

  props: {
    responsiveImage: {
      type: Object as PropType<IResponsiveImage>,
      required: false,
      default: null
    },
    picture: {
      type: Object as PropType<TMobileDesktopImage>,
      required: false,
      default: null
    },
    src: {
      type: String,
      required: false,
      default: null
    },
    fallback: {
      type: String as PropType<EFallbackImageTypes>,
      required: false,
      default: EFallbackImageTypes.Deafult
    },
    srcset: {
      type: String,
      required: false,
      default: null
    },
    sizes: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    bannerAspectRatio: {
      type: Boolean,
      required: false,
      default: false
    },
    objectFitContain: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: Number,
      required: false,
      default: null
    },
    height: {
      type: Number,
      required: false,
      default: null
    }
  },

  setup(props: any) {
    const loading = ref(true)
    const error = ref(false)
    const imageRef = ref<HTMLImageElement | any | null>(null)

    // Load image when component in view
    const { stop } = useIntersectionObserver(
      imageRef,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          imageRef.value.src = imageRef.value.dataset.src
          stop()
        }
      }
    )

    const breakpoint = computed(() => {
      return props.picture?.breakpoint || 850
    })

    const backgroundImageClass = computed(() => {
      if (error.value) {
        return (
          'os-image-fallback--' + props.responsiveImage?.fallback ||
          props.fallback
        )
      }
      const background = props.responsiveImage?.backgroundClass || null
      return {
        ...(background && {
          [`${background}`]: true ?? false
        })
      }
    })

    const fitCover = computed(() => {
      return (
        (props.responsiveImage && props.responsiveImage?.imageIsPlaceHolder) ||
        false
      )
    })

    const onLoad = () => {
      loading.value = false
    }

    const onError = () => {
      loading.value = false
      error.value = true
    }

    return {
      loading,
      error,
      breakpoint,
      backgroundImageClass,
      fitCover,
      imageRef,
      onLoad,
      onError
    }
  }
}
</script>
